import { createKioskDataMock } from "../models/__mock__/createKioskDataMock"
import { IKioskPaymentData, KioskData } from "../models/IKioskData"
import { isForcedKiosk } from "../utils/isForcedKiosk"

import { getKioskData } from "./getKioskData"

export async function getKioskPaymentData(): Promise<
  IKioskPaymentData | undefined
> {
  if (isForcedKiosk()) {
    return formatKioskDataToPaymentData(createKioskDataMock())
  }

  const kioskData = await getKioskData()

  return formatKioskDataToPaymentData(kioskData)
}

function formatKioskDataToPaymentData(
  kioskData: KioskData
): IKioskPaymentData | undefined {
  if (!kioskData.isKiosk || !kioskData.hasPinTerminal) {
    return undefined
  }

  return {
    terminalId: kioskData.pinTerminalId,
    kioskId: `${kioskData.store}-${kioskData.storeDepartment}`,
  }
}
