enum ExtensionId {
  ACC = "bfblbleokjnmiigkafjmpgkmdeojfhka",
  PROD = "hmgofimfefbcoomlplagdbofhglfkkpl",
}

export function getExtensionId(): string {
  if (process.env.REACT_APP_KIOSK_EXTENSION_ID) {
    return process.env.REACT_APP_KIOSK_EXTENSION_ID
  }

  const domain = document.location.host

  return domain.includes("acceptatie") ? ExtensionId.ACC : ExtensionId.PROD
}
