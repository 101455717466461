import { IChromeResponse, KioskMessages } from "../models/IKioskData"

export function sendChromeMessage<T = unknown>(
  extensionId: string,
  message: KioskMessages,
  payload?: unknown
): Promise<T> {
  if (!("chrome" in window) || !chrome?.runtime?.sendMessage) {
    throw new Error("Chrome is not defined")
  }

  try {
    return new Promise((resolve, reject) =>
      chrome.runtime.sendMessage(
        extensionId,
        { key: message, payload },
        (response: IChromeResponse<T>): void => {
          if (!response) {
            return reject()
          }

          if ("data" in response) {
            return resolve(response.data)
          }
          if ("error" in response) {
            return reject(response.error)
          }

          return reject()
        }
      )
    )
  } catch (e) {
    throw new Error(e)
  }
}
