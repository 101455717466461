import { IChromeMessageError } from "../models/IKioskData"
import { sendChromeMessage } from "../utils/sendChromeMessage"

import { getExtensionId } from "./getExtensionId"

export async function clearCache(): Promise<boolean> {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log(
      "Cache cleared in development mode. Faking clear cache function"
    )

    return true
  }
  try {
    await sendChromeMessage<string | IChromeMessageError>(
      getExtensionId(),
      "CLEAR_ALL_CACHE"
    )

    return true
  } catch {
    return false
  }
}
