import { createKioskDataMock } from "../models/__mock__/createKioskDataMock"
import { KioskData } from "../models/IKioskData"
import { isForcedKiosk } from "../utils/isForcedKiosk"
import { sendChromeMessage } from "../utils/sendChromeMessage"

import { getExtensionId } from "./getExtensionId"

export async function getKioskData(): Promise<KioskData> {
  if (isForcedKiosk()) {
    return createKioskDataMock()
  }

  try {
    return await sendChromeMessage<KioskData>(
      getExtensionId(),
      "GET_KIOSK_DATA"
    )
  } catch {
    return {
      isKiosk: false,
    }
  }
}
