export type SetKioskDataErrorMessage = "Invalid" | "Required"
export type SetKioskDataErrorField =
  | "startUrl"
  | "pinTerminalId"
  | "store"
  | "storeUID"

export class SetKioskDataError extends Error {
  fieldName: SetKioskDataErrorField

  constructor(message: SetKioskDataErrorMessage, name: SetKioskDataErrorField) {
    super(message)

    this.fieldName = name
  }
}
