import { KioskWithPin } from "../IKioskData"

const defaults: KioskWithPin = {
  isKiosk: true,
  id: "Random ID",
  storeUID: "",
  startUrl: "/assortiment/k/behang",
  hasPinTerminal: true,
  pinTerminalId: "P400Plus-275103114",
  store: "EERKU",
  version: "",
  storeDepartment: "Behang",
  screenSaverIdleTime: 1000 * 60 * 5, // 5 minutes
  screenSaverVideoUrl:
    "https://videos.ctfassets.net/7k5qhlbm8iou/3tzHccUyiZocyPvBtsNoRb/7c8e35265b08dcbd9fd708b881e4a3cb/screensaver.mp4", // Video of gamma
  debugEnabledTime: null,
}

export function createKioskDataMock(
  overrides?: Partial<KioskWithPin>
): KioskWithPin {
  return {
    ...defaults,
    ...overrides,
  }
}
