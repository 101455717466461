import { IKioskAdminData } from "../models/IKioskData"
import { SetKioskDataError } from "../models/SetKioskDataError"
import { sendChromeMessage } from "../utils/sendChromeMessage"

import { getExtensionId } from "./getExtensionId"

export async function setKioskData(
  kioskData: IKioskAdminData
): Promise<string> {
  try {
    return await sendChromeMessage<string>(
      getExtensionId(),
      "SET_KIOSK_DATA",
      kioskData
    )
  } catch (error) {
    if ("name" in error && "message" in error) {
      throw new SetKioskDataError(error.message, error.name)
    }

    throw new Error("Something went wrong")
  }
}
