export function isForcedKiosk(): boolean {
  if (
    process.env.REACT_APP_FORCE_KIOSK &&
    process.env.REACT_APP_FORCE_KIOSK === "true"
  ) {
    return true
  }

  return false
}
