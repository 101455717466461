import { isForcedKiosk } from "../utils/isForcedKiosk"
import { sendChromeMessage } from "../utils/sendChromeMessage"

import { getExtensionId } from "./getExtensionId"

export async function isKiosk(): Promise<boolean> {
  if (isForcedKiosk()) {
    return true
  }

  // For previous Kiosk functionality check for headers first
  if (window.navigator.userAgent.includes("IG-Kiosk")) {
    return true
  }

  // Otherwise check for extension
  const timeStart = performance.now()

  try {
    return await sendChromeMessage<boolean>(
      getExtensionId(),
      "VALIDATE_IS_KIOSK"
    )
  } catch {
    return false
  } finally {
    const timeEnd = performance.now()

    // eslint-disable-next-line no-console
    console.info("Time to resolve isKiosk ", {
      duration: timeEnd - timeStart,
    })
  }
}
