import { CredentialRoles, ICredentialsResponse } from "../models/ICredentials"
import { sendChromeMessage } from "../utils/sendChromeMessage"

import { getExtensionId } from "./getExtensionId"

interface IValidateCredentials {
  password: string
}

export async function validateCredentials(
  credentials: IValidateCredentials
): Promise<CredentialRoles | null> {
  try {
    const response = await sendChromeMessage<ICredentialsResponse>(
      getExtensionId(),
      "VALIDATE_CREDENTIALS",
      credentials
    )

    return response.role
  } catch {
    return null
  }
}
